<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'quarter'}" v-on:click="setTempPeriod('quarter')" ref="view_type_quarter"  data-toggle="pill" data-period="quarter">{{ $t('period.quarter') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}" v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>
            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-goals-list-filter" v-on:submit.prevent="applyFilter()">

              <!-- Vendedor -->
              <!--<h6 class="mb-3 mt-5">{{ $t('form.report.seller') }}</h6>-->
              <div class="mt-5">
                  <div class="md-form mb-0 ">
                    <div class="md-form mb-0">
                      <select class="md-form-control" :value="filters.filEmployee" id="employee" name="employee" ref="employee">
                        <option v-for="(item, index) in sellerList" v-bind:key="index" :value="item.id"  :selected="filters.filEmployee === item.id" >{{ item.nome }}</option>
                      </select>
                      <label :class="{'active': true}">{{ $t('form.report.seller') }}</label>
                    </div>
                  </div>
              </div>

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in $util.years(2022)" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'quarter'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filQuarter" id="quarter" name="quarter" ref="quarter" >
                          <option v-for="(quarter, index) in $util.quarters()" v-bind:key="index" :value="index" :selected="filters.filQuarter === parseInt(index)" >{{ quarter }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.quarter') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in $util.months()" v-bind:key="index" :value="index" :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-10 mb-3 filter-container">
        <filter-container :activeFilterList="getActiveFilterList()" />
      </div>
      <div class="col-12">
        <button-back>
          RELATÓRIO DE ACOMPANHAMENTO DE METAS
        </button-back>
      </div>

      <!-- Queries -->
      <card class-root="col-12" v-if="showQueries">
        <div class="row">
          <div class="col-12 mb-5" v-for="(query, index) in reportData.queries" v-bind:key="index">
            <div class="title">{{ index.toUpperCase() }}</div>
            <div >
              <pre>{{ query.sql }}</pre>
              <table class="table table-striped" v-if="query.params">
                <thead>
                    <tr>
                  <td>Nome</td>
                  <td>Valor</td>
                    </tr>
                </thead>
                <tr v-for="(param, index) in query.params" :key="index">
                  <td>{{ param.name }}</td>
                  <td>{{ param.value }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </card>

      <!-- Vendedor -->
      <card class-root="col-12">
          <div class="flexbox mb-4 mt-3">
            <div  v-if="isSuperAdmin" v-on:click="toogleQueries" class="query-info"><i class="fa fa-question-circle font-20"></i><md-tooltip md-direction="right">Visualizar queries</md-tooltip></div>
            <div class="media">
              <span class="position-relative d-inline-block mr-4" v-if="reportData.vendedor.imagem">
                <img class="rounded-circle"
                     onerror="this.src='https://assinatura.royalcargo.com.br/temporario/apresentacao/fun/default.jpg';"
                     :src="reportData.vendedor.imagem"
                     alt="image" width="100">
              </span>
              <div class="media-body">
                <div class="h4">{{ reportData.vendedor.apelido ? reportData.vendedor.apelido : reportData.vendedor.nome }}</div>
                <div class="text-muted">{{ reportData.vendedor.cargo }}</div>
              </div>
            </div>
          </div>
      </card>

      <!-- Comissão -->
      <card-bonus :value="reportData" :black="true" v-if="reportData.mostrar_comissao">
        <template slot="title">
          Comissão estimada
        </template>
      </card-bonus>

      <!-- FCL -->
      <card-goal class-root="col-12 col-md-3 mb-4" :realizado="reportData.volume.em.realizado + reportData.volume.im.realizado"
        :meta="reportData.volume.em.meta + reportData.volume.im.meta">
        <template slot="title">
          FCL
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-fcl-boarded"
            indicator="fcl"
            :value="(reportData.volume) ? reportData.volume.em.realizado + reportData.volume.im.realizado : 0.0"
            :goal="(reportData.volume) ? ((reportData.volume.em.meta + reportData.volume.im.meta) * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.em.realizado + reportData.volume.im.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            FCL
          </donnut-chart>
        </template>
      </card-goal>

      <!-- LCL -->
      <card-goal class-root="col-12 col-md-3 mb-4" :realizado="reportData.volume.lcl.realizado" :meta="reportData.volume.lcl.meta">
        <template slot="title">
          LCL
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-lcl-boarded"
            indicator="lcl"
            :value="(reportData.volume) ? reportData.volume.lcl.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.lcl.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.lcl.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            LCL
          </donnut-chart>
        </template>
      </card-goal>

      <!-- Aéreo -->
      <card-goal class-root="col-12 col-md-3 mb-4" :decimals="2"  :realizado="reportData.volume.ea.realizado + reportData.volume.ia.realizado" :meta="reportData.volume.ea.meta + reportData.volume.ia.meta">
        <template slot="title">
          AÉREO
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-aereo-boarded"
            indicator="aereo"
            :value="(reportData.volume) ? (reportData.volume.ea.realizado + reportData.volume.ia.realizado) : 0.0"
            :goal="(reportData.volume) ? ((reportData.volume.ea.meta + reportData.volume.ia.meta) * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.ea.realizado + reportData.volume.ia.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            AÉREO
          </donnut-chart>
        </template>
      </card-goal>

      <!-- Seguro -->
      <card-goal class-root="col-12 col-md-3 mb-4" :realizado="reportData.volume.seguro.realizado" :meta="reportData.volume.seguro.meta">
        <template slot="title">
          SEGURO
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-seguro-boarded"
            indicator="seguro"
            :value="(reportData.volume) ? reportData.volume.seguro.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.seguro.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.seguro.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            SEGURO
          </donnut-chart>
        </template>
      </card-goal>

      <div class="col-12">
        <div class="subtitle">
          TOTAL POR PRODUTO
        </div>
      </div>

      <!-- IM -->
      <card-goal class-root="col-12 col-md-3 mb-4" :realizado="reportData.volume.im.realizado"
        :meta=" reportData.volume.im.meta">
        <template slot="title">
          IM
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-im-total"
            indicator="total-im"
            :value="(reportData.volume) ? reportData.volume.im.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.im.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.im.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            LCL
          </donnut-chart>
        </template>
      </card-goal>

      <!-- EM -->
      <card-goal class-root="col-12 col-md-3 mb-4" :realizado="reportData.volume.em.realizado"
        :meta=" reportData.volume.em.meta">
        <template slot="title">
          EM
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-em-total"
            indicator="total-em"
            :value="(reportData.volume) ? reportData.volume.em.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.em.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.em.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            LCL
          </donnut-chart>
        </template>
      </card-goal>

      <!-- IA - Por Operação -->
      <card-goal  v-if="reportData.aereo_por_operacao" class-root="col-12 col-md-3 mb-4"
        :decimals="2" :realizado="reportData.volume.ia.realizado" :meta=" reportData.volume.ia.meta">
        <template slot="title">
          IA
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-aereo-ia"
            indicator="total-aereo"
            :value="(reportData.volume) ? reportData.volume.ia.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.ia.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.ia.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            IA
          </donnut-chart>
        </template>
      </card-goal>

      <!-- EA - Por Operação -->
      <card-goal v-if="reportData.aereo_por_operacao" class-root="col-12 col-md-3 mb-4"
        :decimals="2" :realizado="reportData.volume.ea.realizado" :meta=" reportData.volume.ea.meta">
        <template slot="title">
          EA
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-aereo-ea"
            indicator="total-aereo"
            :value="(reportData.volume) ? reportData.volume.ea.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.ea.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.ea.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            EA
          </donnut-chart>
        </template>
      </card-goal>

      <!-- Aéreo - Agrupado -->
      <card-goal v-if="!reportData.aereo_por_operacao" class-root="col-12 col-md-3 mb-4" :decimals="2"
        :realizado="reportData.volume.ea.realizado + reportData.volume.ia.realizado"
        :meta="reportData.volume.ea.meta + reportData.volume.ia.meta">
        <template slot="title">
          Aéreo
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-aereo-total"
            indicator="total-aereo"
            :value="(reportData.volume) ? (reportData.volume.ea.realizado + reportData.volume.ia.realizado) : 0.0"
            :goal="(reportData.volume) ? ((reportData.volume.ea.meta + reportData.volume.ia.meta) * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.ea.realizado + reportData.volume.ia.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            Aéreo
          </donnut-chart>
        </template>
      </card-goal>

      <!-- CABOTAGEM -->
      <card-goal class-root="col-12 col-md-3 mb-4" :realizado="reportData.volume.cabotagem.realizado"
        :meta=" reportData.volume.cabotagem.meta">
        <template slot="title">
          CABOTAGEM
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-cabotagem-total"
            indicator="total-cabotagem"
            :value="(reportData.volume) ? reportData.volume.cabotagem.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.cabotagem.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.cabotagem.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            LCL
          </donnut-chart>
        </template>
      </card-goal>

      <!-- LCL -->
      <card-goal class-root="col-12 col-md-3 mb-4" :realizado="reportData.volume.lcl.realizado" :meta=" reportData.volume.lcl.meta">
        <template slot="title">
          LCL
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-lcl-total"
            indicator="total-lcl"
            :value="(reportData.volume) ? reportData.volume.lcl.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.lcl.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.lcl.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            LCL
          </donnut-chart>
        </template>
      </card-goal>

      <!-- NR -->
      <card-goal class-root="col-12 col-md-3 mb-4" :realizado="reportData.volume.nr.realizado" :meta=" reportData.volume.nr.meta">
        <template slot="title">
          ROD. NACIONAL
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-nr-total"
            indicator="total-nr"
            :value="(reportData.volume) ? reportData.volume.nr.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.nr.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.nr.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            LCL
          </donnut-chart>
        </template>
      </card-goal>

      <!-- INTR -->
      <card-goal class-root="col-12 col-md-3 mb-4" :realizado="reportData.volume.intr.realizado" :meta=" reportData.volume.intr.meta">
        <template slot="title">
          ROD. INTERNACIONAL
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-maritime-intr-total"
            indicator="total-intr"
            :value="(reportData.volume) ? reportData.volume.intr.realizado : 0.0"
            :goal="(reportData.volume) ? (reportData.volume.intr.meta * 1.0) : 0.0"
            :percentMark="(reportData.volume) ? (getPercentMark(reportData.volume.intr.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            LCL
          </donnut-chart>
        </template>
      </card-goal>

      <!-- ATIVIDADES-->
      <div class="col-12">
        <div class="subtitle">
          ATIVIDADES
        </div>
      </div>

      <!-- APRESENTAÇÃO -->
      <card-goal class-root="col-12 col-md-3 mb-4" label-realizado="Realizado" :realizado="reportData.kpi.apresentacao.realizado"  :meta=" reportData.kpi.apresentacao.meta">
        <template slot="title">
          APRESENTAÇÃO
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-kpi-apresentacao"
            indicator="total-intr"
            :value="(reportData.kpi.apresentacao) ? reportData.kpi.apresentacao.realizado : 0.0"
            :goal="(reportData.kpi.apresentacao) ? (reportData.kpi.apresentacao.meta * 1.0) : 0.0"
            :percentMark="(reportData.kpi.apresentacao) ? (getPercentMark(reportData.kpi.apresentacao.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            Apresentação
          </donnut-chart>
        </template>
      </card-goal>

      <!-- VISITA -->
      <card-goal v-if="filters && filters.filEmployee !== 44506" class-root="col-12 col-md-3 mb-4" label-realizado="Realizado"
        :realizado="reportData.kpi.visita.realizado" :meta=" reportData.kpi.visita.meta">
        <template slot="title">
          VISITA
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-kpi-visita"
            indicator="total-intr"
            :value="(reportData.kpi.visita) ? reportData.kpi.visita.realizado : 0.0"
            :goal="(reportData.kpi.visita) ? (reportData.kpi.visita.meta * 1.0) : 0.0"
            :percentMark="(reportData.kpi.visita) ? (getPercentMark(reportData.kpi.visita.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            VISITA
          </donnut-chart>
        </template>
      </card-goal>

      <!-- Contato Tel -->
      <card-goal class-root="col-12 col-md-3 mb-4" label-realizado="Realizado" :realizado="reportData.kpi.ligacao.realizado"  :meta=" reportData.kpi.ligacao.meta">
        <template slot="title">
          CONTATOS TEL.
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-kpi-ligacao"
            indicator="total-intr"
            :value="(reportData.kpi.ligacao) ? reportData.kpi.ligacao.realizado : 0.0"
            :goal="(reportData.kpi.ligacao) ? (reportData.kpi.ligacao.meta * 1.0) : 0.0"
            :percentMark="(reportData.kpi.ligacao) ? (getPercentMark(reportData.kpi.ligacao.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            Contatos Tel.
          </donnut-chart>
        </template>
      </card-goal>

      <!-- Contato Skp/Whats -->
      <card-goal class-root="col-12 col-md-3 mb-4" label-realizado="Realizado" :realizado="reportData.kpi.skpWhats.realizado"  :meta=" reportData.kpi.skpWhats.meta">
        <template slot="title">
          CONTATOS SKP/WHATS
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-kpi-skpwhats"
            indicator="total-intr"
            :value="(reportData.kpi.skpWhats) ? reportData.kpi.skpWhats.realizado : 0.0"
            :goal="(reportData.kpi.skpWhats) ? (reportData.kpi.skpWhats.meta * 1.0) : 0.0"
            :percentMark="(reportData.kpi.skpWhats) ? (getPercentMark(reportData.kpi.skpWhats.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            Contatos SKP/Whats
          </donnut-chart>
        </template>
      </card-goal>

      <!-- E-mail -->
      <card-goal class-root="col-12 col-md-3 mb-4" label-realizado="Realizado" :realizado="reportData.kpi.email.realizado"  :meta=" reportData.kpi.email.meta">
        <template slot="title">
          E-MAIL
        </template>

        <template slot="graphic">
          <donnut-chart
            elementId="chart-kpi-email"
            indicator="total-intr"
            :value="(reportData.kpi.email) ? reportData.kpi.email.realizado : 0.0"
            :goal="(reportData.kpi.email) ? (reportData.kpi.email.meta * 1.0) : 0.0"
            :percentMark="(reportData.kpi.email) ? (getPercentMark(reportData.kpi.email.realizado)) : 0"
            width="65px"
            height="65px"
            :topOffset="25"
            :empty-color="'#e8e8e8'">
            E-mail
          </donnut-chart>
        </template>
      </card-goal>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Card from '@/views/Report/Seller/2022/Card'
import CardGoal from '@/views/Report/Seller/2022/CardGoal'
import ButtonBack from '@/components/Navigate/ButtonBack'
import DonnutChart from '@/components/Chart/DonnutChartV2'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import CustomerService from '@/services/CustomerService'
import EmployeeService from '@/services/EmployeeService'
import ReportService from '@/services/ReportService'
import CardBonus from '@/views/Report/Seller/2022/CardBonus'
// import SolidDonutChart from '@/components/Chart/amcharts4/SolidDonutChart'

export default {
  name: 'SellerQuarter',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.title') + ' ' + this.$i18n.t('report.seller.title') + ' - %s'
    }
  },
  data () {
    return {
      showQueries: false,
      valor: 10.0,
      customerList: [],
      sellerList: [],
      tempPeriod: 'monthly',
      seller: {},
      reportData: {
        mostrar_comissao: true,
        vendedor: {
          id: null,
          nome: null,
          apelido: null,
          cargo: null,
          imagem: null
        },
        volume: {
          aereo: {
            realizado: 0,
            meta: 0
          },
          ea: {
            realizado: 0,
            meta: 0
          },
          ia: {
            realizado: 0,
            meta: 0
          },
          im: {
            realizado: 0,
            meta: 0
          },
          em: {
            realizado: 0,
            meta: 0
          },
          cabotagem: {
            realizado: 0,
            meta: 0
          },
          lcl: {
            realizado: 0,
            meta: 0
          },
          nr: {
            realizado: 0,
            meta: 0
          },
          intr: {
            realizado: 0,
            meta: 0
          },
          seguro: {
            n_total: 0,
            n_realizados: 0,
            realizado: 0,
            is_percent: true,
            meta: 0
          }
        },
        kpi: {
          visita: {
            realizado: 0,
            meta: 0
          },
          apresentacao: {
            realizado: 0,
            meta: 0
          },
          ligacao: {
            realizado: 0,
            meta: 0
          },
          skpWhats: {
            realizado: 0,
            meta: 0
          },
          email: {
            realizado: 0,
            meta: 0
          }
        }
      },
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        employee: null,
        year: null,
        month: null
      },
      firstFilter: true,
      filter: {}
    }
  },
  components: {
    CardBonus,
    Card,
    CardGoal,
    FilterSideBar,
    Loading,
    FilterContainer,
    ButtonBack,
    DonnutChart
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    if (this.user) {
      this.getReportSellerData()
    }
    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    toogleQueries () {
      this.showQueries = !this.showQueries
    },
    tableHeaders () {
      let headers = [
        {
          title: 'Q1',
          period: 1
        },
        {
          title: 'Q2',
          period: 2
        },
        {
          title: 'Q3',
          period: 3
        },
        {
          title: 'Q4',
          period: 4
        }
      ]

      if (this.filters.filPeriodo === 'monthly') {
        headers = []
        for (let i = 1; i <= 12; i++) {
          headers.push(
            {
              title: this.$i18n.t('form.goal.idFrequency.monthly.' + i),
              period: i
            }
          )
        }
      }

      return headers
    },
    getValueByPeriod (indicator, attribute, filters, period) {
      let type = filters.filPeriodo
      let value = 0

      if (type === 'monthly') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]

          if (period === item.month) {
            value = item[attribute]
            break
          }
        }
      } else if (type === 'quarter') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]
          let quarter = parseInt(Math.ceil((item.month) / 3))
          if (period === quarter) {
            value += item[attribute]
          }
        }
      } else if (type === 'yearly') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]
          value += item[attribute]
        }
      }

      return this.numberFormat(value, 0, ',', '.')
    },
    getActiveFilterList () {
      let activeFilterList = {
        period: null,
        employee: null,
        year: null
      }

      if (this.filters.filPeriodo === 'quarter') {
        activeFilterList.quarter = null
      } else if (this.filters.filPeriodo === 'monthly') {
        activeFilterList.month = null
      }

      return activeFilterList
    },
    getTitle () {
      let title = ''

      if (this.filters.filPeriodo === 'monthly') {
        title = 'RESULTADO MENSAL'
      } else if (this.filters.filPeriodo === 'quarter') {
        title = 'RESULTADO TRIMESTRAL'
      } else if (this.filters.filPeriodo === 'yearly') {
        title = 'RESULTADO ANUAL'
      }

      return title
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getPercentMark (value) {
      let goalOnPeriod = 1
      let percentMark = 100

      if (value.goal > 0) {
        goalOnPeriod = value.goal * 1.0
      }
      percentMark = (value.goal * 100 / goalOnPeriod)

      return percentMark
    },
    getQuarter (date) {
      let quarter = parseInt(Math.ceil((date.getMonth() + 1) / 3))

      return quarter
    },
    getPercentualInsuranceGoal (totalFiles, percent) {
      let totalPercent = 0

      if (totalFiles) {
        totalPercent = totalFiles * percent / 100
      }

      return totalPercent
    },
    buildFilters () {
      if (['monthly', 'quarter', 'yearly'].indexOf(this.filters.filPeriodo) === -1) {
        this.filters.filPeriodo = 'monthly'
      }

      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      let today = new Date()
      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Semestre: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */
      if (!this.filters.filYear) {
        this.filters.filYear = today.getFullYear()
      }

      if ((!this.filters.filQuarter)) {
        this.filters.filQuarter = this.getQuarter(today)
      }

      if (!this.filters.filMonth) {
        this.filters.filMonth = today.getMonth() + 1
      }

      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // Senão filtro pelo primeiro vendedor da lista
        this.filters.filEmployee = this.sellerList[0].id_employee
      }

      this.filter.employee = this.filters.filEmployee
      this.filter.year = this.filters.filYear
      this.filter.quarter = this.filters.filQuarter
      this.filter.month = this.filters.filMonth

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    // Filter
    applyFilter () {
      // Period
      if (this.$refs.view_type_quarter.classList.contains('active')) {
        this.filters.filPeriodo = 'quarter'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      this.filters.filEmployee = this.$refs.employee ? parseInt(this.$refs.employee.value) : null
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filQuarter = this.$refs.quarter ? parseInt(this.$refs.quarter.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      /* Todo: Reload Charts */
      this.getReportSellerData()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filQuarter = this.getQuarter(today)
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'monthly'

      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // filtro pelo primeiro vendedor da lista
        this.filters.filEmployee = this.sellerList[0].id_employee
      }

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.getReportSellerData()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showGoal (number, thousandsSep, defaultValue) {
      if (number && number > 0) {
        return this.showNumber(number, thousandsSep, defaultValue)
      }

      return defaultValue
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (thousandsSep !== '') {
        thousandsSep = thousandsSep || '.'
      }

      // eslint-disable-next-line
      if (number != parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showIntegerGoal (number, thousandsSep, defaultValue) {
      if (number && number > 0) {
        return this.showInteger(number, thousandsSep, defaultValue)
      }

      return defaultValue
    },
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    // End Filter
    getReportSellerData () {
      let me = this
      this.isLoading = true
      if (this.$store.getters.userInfo) {
        EmployeeService.getSellers({ per_page: 1000 }).then(res => {
          me.sellerList = res.data.data

          me.sellerList.unshift({ id: null, nome: 'TODOS' })

          me.buildFilters()
          ReportService.getSellerQuarterNew(this.filter.employee, this.filter).then(res => {
            me.seller = res.data.seller
            me.reportData = res.data
            this.isLoading = false
          }).finally(function () {
            me.isLoading = false
          })
        })
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      let filters = {
        'searchTerm': search
      }
      CustomerService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
      })
    },
    /**
     * DataTransform to display Quarter Progresss Chart Bar
     *
     * @param data  {Array}   unformatted chart data
     */
    barChartTransform (data) {
      let transformedGoal = data.map(x => (
        x.goal
        // x.total_goal
      ))

      let transformedRealized = data.map(x => (
        x.realized
        // x.total_realized
      ))

      let colors = []

      for (let i = 0; i < transformedRealized.length; i++) {
        if (transformedRealized[i] < transformedGoal[i]) {
          colors.push('#FFD863')
        } else {
          colors.push('#008f59')
        }
      }

      return [
        {
          data: transformedGoal,
          type: 'line',
          fill: false,
          borderWidth: 2,
          borderColor: '#84754E',
          backgroundColor: '#84754E'
        },
        {
          data: transformedRealized,
          fill: false,
          backgroundColor: colors
        }
      ]
    }
  },
  computed: {
    showNewChart () {
      if (this.reportData.summary && this.reportData.ano < 2021) {
        return false
      }
      return true
    },
    isDirector () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_DIRECTOR') !== -1) {
        return true
      }
      return false
    },
    isFinancial () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_FINANTIAL') !== -1) {
        return true
      }
      return false
    },
    isComercialAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_COMERCIAL_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isSeller () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        if (userInfo.active_employee) {
          if (userInfo.active_employee.is_seller) {
            return true
          }
        }
      }
      return false
    },
    showEmployeeFilter () {
      if (this.user) {
        let showEmployee = false
        if (this.user.roles.filter(role => role === 'ROLE_DIRECTOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_COMERCIAL_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SALES_COORDINATOR').length > 0) {
          showEmployee = true
        }

        return showEmployee
      } else {
        return false
      }
    },
    totalFiles () {
      let total = 0

      let reportData = this.reportData
      if (reportData.details) {
        total = (reportData.details.fcl.import.boarded.files.current +
        reportData.details.fcl.export.boarded.files.current +
        reportData.summary.air.current +
        reportData.summary.maritime.lcl.current)
      }

      return total
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      this.getReportSellerData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .subtitle {
    margin-top: 3.0rem;
    margin-bottom: 0.8rem;
  }

  .query-info {
    position: absolute;
    top: 26px;
    right: 36px;
  }
</style>
