<template>
  <div class="indicator mb-2" :class="{ 'indicator-border': showBorder }" :style="{ 'border-color': color}">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="value " :class="{'font-26': isTotal, 'pt-4': isTotal}">
          {{ prefix }} {{ value }}
        </div>
        <div class="label">
          {{ label }}
        </div>
      </div>
      <div class="col-12 col-md-6 pt-1" v-if="$slots.value">
        <div class="value">
          <slot name="value"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    showBorder: {
      type: Boolean,
      required: false,
      default: true
    },
    isTotal: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String,
      default: 'green'
    }
  }
}
</script>

<style scoped>
  .indicator.indicator-border {
    border-left-width: 5px;
    border-left-style: solid;
    border-left-color: #0F9D57;
  }

  .value {
    padding: 0;
    margin-left: 10px;
    font-size: 18px;
  }

  .label {
    padding: 0;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0;
  }
</style>
