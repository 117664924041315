<template>
  <div class="col-12 mb-4" :class="{ 'col-md-3': !showDetails }">
    <div class="card" :class="{ 'card-black': black && !showDetails }" >
      <div class="card-title" v-if="$slots.title">
        <slot name="title"></slot>
        <a v-on:click="toggleDetails" class="action-button">
          <i v-if="!showDetails" class="fa fa-plus-circle"></i>
          <i v-else class="fa fa-minus-circle"></i>
        </a>
      </div>
      <div class="card-body no-padding no-margim">
        <div class="row" v-if="showDetails && value && value.recebimentos">
          <div class="col-12">
            <div class="row">
              <div class="col-12 font-16 mt-3 pl-0">
                Total Recebido
              </div>

              <div class="col-12 col-md-4 pl-0">
                <div class="card-title mb-4 mt-3 font-16">
                  R$ {{ $util.formatNumber(value.recebimentos.total_realizado, 2, ',', '.', 0) }}<br/>
                  <div class="small">Total</div>
                </div>
              </div>
              <div class="col-12 col-md-4 pl-0">
                <div class="card-title mb-4 mt-3 font-16">
                  R$ {{ $util.formatNumber(value.recebimentos.realizado, 2, ',', '.', 0) }}<br/>
                  <div class="small">Comissão Processos</div>
                </div>
              </div>
              <div class="col-12 col-md-4 pl-0" v-if="value.show_mbs">
                <div class="card-title mb-4 mt-3 font-16">
                  R$ {{ $util.formatNumber(value.recebimentos.total_realizado - value.recebimentos.realizado, 2, ',', '.', 0) }}<br/>
                  <div class="small">MBS s/ Rodo.</div>
                </div>
              </div>
            </div>
            <div class="titulo font-16" v-if="value.recebimentos.meta.length === 1">Tier: {{ value.recebimentos.meta[0]['title'] }}</div>
            <div class="titulo font-16" v-if="value.recebimentos.meta.length > 1" >Tier</div>
            <horizontal-gauge-chart v-if="value.recebimentos.meta.length > 1" :ranges="((value.recebimentos) ? value.recebimentos.meta : null)"  :value="value.recebimentos.total_realizado" />
            <div class="row mt-4">
              <div class="col-12 col-md-6">
                  <!-- Faturamento -->
                    <indicator label="Faturamento" :value="value.comissao.percentual_financeiro + '%'" color="#38bd40">
                      <template v-slot:value>
                        R$ {{ $util.formatNumber(value.comissao.financeiro, 2, ',', '.', 0) }}
                      </template>
                    </indicator>
                  <!-- FCL -->
                    <indicator v-if="value.comissao.volume.hasOwnProperty('fcl')" label="FCL" :value="'+' + value.comissao.percentual_fcl + '%'" color="#003f5c">
                      <template v-slot:value>
                        R$ {{ $util.formatNumber(value.comissao.volume.fcl, 2, ',', '.', 0) }}
                      </template>
                    </indicator>
                  <!-- LCL -->
                    <indicator v-if="value.comissao.volume.hasOwnProperty('lcl')" label="LCL" :value="'+' + value.comissao.percentual_lcl + '%'" color="#ffa600">
                      <template v-slot:value>
                        R$ {{ $util.formatNumber(value.comissao.volume.lcl, 2, ',', '.', 0) }}
                      </template>
                    </indicator>
                  <!-- Aéreo -->
                    <indicator v-show="value.comissao.volume.hasOwnProperty('aereo')" label="Aéreo" :value="'+' + value.comissao.percentual_aereo + '%'" color="#668c9d">
                      <template v-slot:value>
                        R$ {{ $util.formatNumber(value.comissao.volume.aereo, 2, ',', '.', 0) }}
                      </template>
                    </indicator>
                <!-- Visitas -->
                <indicator v-if="value.comissao.kpi" label="Visita" :value="'+' + value.comissao.percentual_visita + '%'" color="#668c9d">
                  <template v-slot:value>
                    R$ {{ $util.formatNumber(value.comissao.kpi.visita, 2, ',', '.', 0) }}
                  </template>
                </indicator>
              </div>
              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <indicator label="Comissão Processos" :showBorder="false" :isTotal="true" prefix="R$" :value="$util.formatNumber(calculateBonusProcessos, 2, ',', '.', '-')" color="#668c9d" />
                  </div>
                  <div class="col-12" v-if="value.show_mbs">
                    <indicator label="Comissão MBS 3%" :showBorder="false" :isTotal="true" prefix="R$" :value="$util.formatNumber(calculateBonusMBS3, 2, ',', '.', '-')" color="#668c9d" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="!showDetails">
          <div class="col col-12 mt-3">
            <div class="value">
              R$ {{ $util.formatNumber(calculateBonus, 2, ',', '.', '-') }} <br/>
            </div>
          </div>
          <div class="col col-6 ">
            <div class="small">
              R$ {{ $util.formatNumber(calculateBonusProcessos, 2, ',', '.', '-') }} <br/>
            </div>
            <div class="small">
              Processos
            </div>
          </div>
          <div class="col col-6" v-if="value.show_mbs">
            <div class="small">
              R$ {{ $util.formatNumber(calculateBonusMBS3, 2, ',', '.', '-') }} <br/>
            </div>
            <div class="small">
              MBS 3%
            </div>
          </div>
        </div>
      </div>
      <div class="card-legend" v-if="$slots.legend">
        <slot name="legend"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalGaugeChart from '@/views/Report/Seller/2022/HorizontalGaugeChart'
import Indicator from '@/views/Report/Seller/2022/Indicator'

export default {
  name: 'CardBonus',
  components: {
    HorizontalGaugeChart,
    Indicator
  },
  data () {
    return {
      showDetails: false
    }
  },
  methods: {
    toggleDetails () {
      this.showDetails = !this.showDetails
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    black: {
      type: Boolean,
      default: false,
      required: false
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    classRoot: {
      type: [String, Object, Array],
      default: 'card-fullheight'
    }
  },
  computed: {
    calculateBonus () {
      let bonus = 0.0

      if (this.value.comissao_mbs_sem_rodoviario) {
        bonus += this.value.comissao_mbs_sem_rodoviario.financeiro
      }

      if (this.value.comissao) {
        bonus += this.value.comissao.financeiro
        if (this.value.comissao.volume) {
          if (this.value.comissao.volume.hasOwnProperty('aereo')) {
            bonus += this.value.comissao.volume.aereo
          }

          if (this.value.comissao.volume.hasOwnProperty('fcl')) {
            bonus += this.value.comissao.volume.fcl
          }

          if (this.value.comissao.volume.hasOwnProperty('lcl')) {
            bonus += this.value.comissao.volume.lcl
          }

          if (this.value.comissao.hasOwnProperty('kpi')) {
            bonus += this.value.comissao.kpi.visita
          }
        }
      }
      return bonus
    },
    calculateBonusProcessos () {
      let bonus = 0.0

      if (this.value.comissao) {
        bonus += this.value.comissao.financeiro
        if (this.value.comissao.volume) {
          if (this.value.comissao.volume.hasOwnProperty('aereo')) {
            bonus += this.value.comissao.volume.aereo
          }

          if (this.value.comissao.volume.hasOwnProperty('fcl')) {
            bonus += this.value.comissao.volume.fcl
          }

          if (this.value.comissao.volume.hasOwnProperty('lcl')) {
            bonus += this.value.comissao.volume.lcl
          }

          if (this.value.comissao.hasOwnProperty('kpi')) {
            bonus += this.value.comissao.kpi.visita
          }
        }
      }
      return bonus
    },
    calculateBonusMBS3 () {
      let bonus = 0.0

      if (this.value.comissao_mbs_sem_rodoviario) {
        bonus += this.value.comissao_mbs_sem_rodoviario.financeiro
      }

      return bonus
    }
  }
}
</script>

<style scoped>
  .card-black {
    background-color: #3D3D3D;
  }

  .card {
    margin: 0px;
    padding: 16px 24px;
  }

  .card-title, .card-title b {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .card-black .card-title {
    color: #B1B1B1;
  }
  .card-black .card-body {
    color: #fefefe;
  }

  .no-padding, .no-padding .row {
    padding: 0;
  }
  .no-margim, .no-margim .row {
    margin: 0;
  }

  .no-padding .col{
    padding: 0;
  }

  .grafico {
    width: 70px;
    height: 70px;
    background-color: lightgrey;
    font-size: 9px;
    text-align: center;
  }

  .value {
    font-size: 22px;
  }

  .action-button {
    position: absolute;
    right: 19px;
  }

  .card-black .action-button i {
    color: #f1f1f1;
  }

  .action-button i {
    color: darkgrey;
  }

  .indicator-divider {
    border: 1px solid #3f3f3f;
  }
</style>
